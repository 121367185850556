


export const layouts = {
'AdminLayout': () => import('/home/cleavr/app.84academy.com/releases/20250324102907036/src/app/layouts/AdminLayout.vue'),
'AppLayout': () => import('/home/cleavr/app.84academy.com/releases/20250324102907036/src/app/layouts/AppLayout.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'AppLayout'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
